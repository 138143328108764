<template>

  <section class="mt-4 pb-5">

    <div class="container">
      <div class="inner_page_two_sides">

        <div class="right_side">

          <div class="section_header mt-3">
            <span>{{ $t('reset.reset_password') }}</span>
          </div>
          <div v-if="response === null">
            <div class="data_input_wrapper">

              <div>
                <input v-model.trim="$v.password.$model" class="mt-3" :placeholder="$t('common.password')" type="password"/>
                <br/>
                <div v-if="$v.password.$dirty && !$v.password.required" style="font-size: 12px; color: #ff1313">{{ $t('common.enter_pass') }}</div>
                <div v-if="$v.password.$dirty && !$v.password.minLength" style="font-size: 12px; color: #ff1313">{{ $t('common.invalid_pass') }}</div>

                <input v-model.trim="$v.repeatPassword.$model" class="mt-3" :placeholder="$t('common.repeat_pass')" type="password"/>
                <br/>
                <div v-if="$v.repeatPassword.$dirty && !$v.repeatPassword.sameAsPassword" style="font-size: 12px; color: #ff1313">{{ $t('common.not_same_pass') }}</div>
              </div>
              <br>
            </div>

            <button :disabled="$v.$invalid" class="btn btn-primary mt-4" style="margin-left: 10px"
                    type="button" v-on:click="next">{{ $t('common.next') }}</button>
          </div>
          <div v-else-if="response.result === true">
            <div class="alert alert-success" role="alert">
              ✅ {{ $t('reset.changed') }}
            </div>
          </div>
          <div v-else-if="response.result === false">
            <div class="alert alert-danger" role="alert">
              ⛔ {{ response.error }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: 'ResetPassword',

  props: {
    uuid: String
  },

  data: function () {
    return {
      password: '',
      repeatPassword: '',
      response: null
    }
  },

  validations() {
    var validation = {}

    validation.password = {
      required,
      minLength: minLength(6)
    }

    validation.repeatPassword = {
      sameAsPassword: sameAs('password')
    }

    return validation
  },

  methods: {
    next: function () {

      let reqBody = {
        token: this.uuid,
        newPassword: this.password,
      };

      axios.post('/api/auth/reset_password/change', null, {params: reqBody}).then(
          response => {
            this.response = response.data
          }
      ).catch(function (error) {
        if (error.response) {
          if (error.response.status === 409) {
            //todo process error
          }
        }
      });
    }
  }
}
</script>
